import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: '首页 - 南京朗赢信息技术有限公司'
    }
  },
  {
    path: '/news/:id',
    name: 'news-detail',
    component: () => import(/* webpackChunkName: "news-detail" */ '../views/NewsDetailView.vue'),
    meta: {
      title: '新闻详情 - 南京朗赢信息技术有限公司'
    }
  },
  {
    path: '/consult/:id/:productId?',
    name: 'consult',
    component: () => import(/* webpackChunkName: "news-detail" */ '../views/ConsultView.vue'),
    meta: {
      title: '企业咨询 - 南京朗赢信息技术有限公司'
    }
  },
  {
    path: '/xinwen/:id?',
    name: 'news-list',
    component: () => import(/* webpackChunkName: "news-detail" */ '../views/NewsListView.vue'),
    meta: {
      title: '新闻中心 - 南京朗赢信息技术有限公司'
    }
  },
  {
    path: '/about/:id?',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: {
      title: '关于我们 - 南京朗赢信息技术有限公司'
    }
  },
  {
    path: '/products/:id',
    name: 'products',
    component: () => import(/* webpackChunkName: "products" */ '../views/ProductsView.vue'),
    meta: {
      title: '产品 - 南京朗赢信息技术有限公司'
    }
  },
  {
    path: '/services/:id?',
    name: 'services',
    component: () => import(/* webpackChunkName: "services" */ '../views/ServicesView.vue'),
    meta: {
      title: '服务 - 南京朗赢信息技术有限公司'
    }
  },
  {
    path: '/cases/:id?',
    name: 'cases',
    component: () => import(/* webpackChunkName: "cases" */ '../views/CasesView.vue'),
    meta: {
      title: '客户案例 - 南京朗赢信息技术有限公司'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

// 全局路由守卫
router.beforeEach((to, from, next) => {
  // 更新页面标题
  document.title = to.meta.title || '南京朗赢信息技术有限公司'
  next()
})

export default router
