<template>
  <div class="home">
    <div class="swiper-container">
      <swiper class="swiper" :options="swiperOptions">
        <swiper-slide v-for="item in bannerList" :key="item.id">
          <el-image :src="item.files" fit="cover" style="width:100%;height:100%; cursor:pointer;" @click="gotoNewsDetail(item)"></el-image>
        </swiper-slide>

        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <!-- 轮播图结束 -->
    <!-- 新闻列表开始 -->
    <div class="news-container">
      <el-row type="flex" justify="center">
        <el-col :span="18">
          <div class="news-category">
            <div class="category-list">
              <template v-for="(item, index) in categories">
                <div :key="item.id" class="category-item"
                  :class="{'active': item.id === activeCategory}" @click="changeCategory(item.id)">
                  {{ item.columnName }}</div>
                <span v-if="index !== categories.length - 1" :key="'divider-' + index"
                  class="category-divider">/</span>
              </template>
            </div>
            <div class="more-btn" @click="$router.push('/xinwen')">了解更多</div>
          </div>
          <div class="news-content">
            <el-empty v-if="!mainNews && !sideNews.length" description="暂无新闻数据"></el-empty>
            <template v-else>
              <div class="news-left">
                <div class="news-main">
                  <template v-if="mainNews && mainNews.contentProperties == 2">
                    <video-player class="vjs-custom-skin" ref="videoPlayer" @click.stop :options="{
                        autoplay: false,
                        controls: true,
                        sources: [{ src: fileUrl + mainNews.fileUrl, type: 'video/mp4' }],
                        fluid: true
                      }" />
                  </template>
                  <template v-else-if="mainNews">
                    <img :src="fileUrl + mainNews.fileUrl" alt="主要新闻图片">
                  </template>
                  <div class="news-info" @click="$router.push(`/news/${mainNews.id}`)">
                    <span class="news-main-title">{{ mainNews.title }}</span>
                    <p class="desc">{{ mainNews.contentText }}</p>
                  </div>
                </div>
              </div>
              <div class="news-right">
                <div v-for="news in sideNews" :key="news.id" class="news-item"
                  @click="$router.push(`/news/${news.id}`)">
                  <img :src="fileUrl + news.fileUrl" alt="新闻图片"
                    v-if="news.fileUrl && news.contentProperties == 1">
                  <div class="news-text">
                    <p class="new-title">{{ news.title }}</p>
                    <p>{{ news.contentText }}</p>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 新闻列表结束 -->
    <!-- 核心产品开始 -->
    <div class="product-container">
      <el-row type="flex" justify="center">
        <el-col :span="18">
          <div class="product-title">
            <h2>核心产品</h2>
            <p>多年技术积累，运维更有保障，技术更放心</p>
          </div>
          <div class="product-list">
            <div class="product-box" v-for="item in productList" :key="item.id">
              <div class="product-content">
                <span class="title">{{item.columnName}}</span>
                <div class="product-items">
                  <div class="item" v-for="child in item.children" :key="child.id"
                    @click="$router.push(`/products/${child.id}`)">{{ child.columnName }}</div>

                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 核心产品结束 -->

    <!-- 解决方案开始 -->
    <div class="solution-container" v-if="solutionList && solutionList.length > 0">
      <el-row type="flex" justify="center">
        <el-col :span="18">
          <div class="solution-content">
            <div class="solution-left">
              <h2>解决方案</h2>
              <p>现已应用于多个领域的企业、检验检测机构</p>
              <div class="more-btn" @click="$router.push(`/consult/4`)">了解更多</div>
            </div>
            <div class="solution-right">
              <div class="solution-grid">
                <div class="solution-item" v-for="item in solutionList" :key="item.id">
                  <img :src="fileUrl + item.banner" alt="食品">
                  <span>{{item.title}}</span>
                </div>

              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 解决方案结束 -->
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";
import { videoPlayer } from "vue-video-player";
import { handleTree } from "@/utils/common";
import {
  getBanner,
  getContentCategory,
  getHomeContentList,
  getMenus,
  getSolutionList,
} from "@/api/frontApi";
export default {
  name: "HomeView",
  components: {
    Swiper,
    SwiperSlide,
    videoPlayer,
  },
  data() {
    return {
      swiperOptions: {
        loop: false,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
          enabled: false
        },
        effect: "fade",
        fadeEffect: {
          crossFade: true,
        },
        speed: 1000,
        cubeEffect: {
          shadow: false,
          slideShadows: true,
          shadowOffset: 20,
          shadowScale: 0.94,
        },
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          bulletClass: "swiper-pagination-bullet",
          bulletActiveClass: "swiper-pagination-bullet-active",
          clickable: true,
        },
      },
      activeCategory: "1",
      categories: [],
      newsList: [],
      sideNews: [],
      mainNews: undefined,
      bannerList: [],
      productList: [],
      solutionList: [],
      fileUrl: process.env.VUE_APP_BASE_FILE_URL,
    };
  },
  computed: {},
  created() {
    this.loadBanners();
    this.loadNewsCategory();
    this.loadMenus();
    this.loadSolutionList();
  },
  methods: {
    changeCategory(columnId) {
      this.activeCategory = columnId;
      this.loadContentList(columnId);
    },
    //加载轮播图
    loadBanners() {
      getBanner().then((res) => {
        this.bannerList = res.data;
        if(this.bannerList.length){
          this.bannerList.forEach(banner => {
            banner.files = this.fileUrl + banner.files;
          });
        }
      });
    },
    //加载新闻列表
    loadNewsCategory() {
      getContentCategory()
        .then((res) => {
          this.categories = res.data;
          this.activeCategory = this.categories[0].id;
          return getHomeContentList(this.activeCategory);
        })
        .then((res) => {
          this.newsList = res.data;
          this.mainNews = res.data[0];
          this.sideNews = res.data.slice(1, 5);
        });
    },
    //加载新闻列表
    loadContentList(categoryId) {
      getHomeContentList(categoryId).then((res) => {
        if (res.data.length > 0) {
          this.newsList = res.data;
          this.mainNews = res.data[0];
          this.sideNews = res.data.slice(1, 5);
        } else {
          this.newsList = [];
          this.mainNews = undefined;
          this.sideNews = [];
        }
      });
    },
    //加载产品
    loadMenus() {
      getMenus().then((res) => {
        let menus = handleTree(res.data, "id");
        this.productList = menus.find((m) => m.columnName == "产品").children;
        console.log(this.productList);
      });
    },
    //加载解决方案
    loadSolutionList() {
      getSolutionList().then((res) => {
        this.solutionList = res.data;
      });
    },
    gotoNewsDetail(item){
      if(item.linkType && item.linkType == '1' && item.linkId){
        this.$router.push(`/news/${item.linkId}`);
      }else if(item.linkType && item.linkType == '3' && item.linkUrl){
        window.open(item.linkUrl, '_blank');
      }
    
    }
  },
};
</script>

<style lang="scss" scoped>
.home {
  .swiper-container {
    position: relative;
    .swiper {
      height: calc(100vw * 795 / 2880);  /* 根据原始图片比例计算高度 */
      max-height: 795px;  /* 限制最大高度 */
      min-height: 400px;  /* 设置最小高度，避免在小屏幕上太矮 */
    }
    .swiper-pagination {
      bottom: 20px !important;

      :deep(.swiper-pagination-bullet) {
        width: 24px !important;
        height: 3px !important;
        background: #cdddfa !important;
        opacity: 1 !important;
        margin: 0 4px !important;
        border-radius: 1.5px !important;
        transition: all 0.3s ease !important;

        &.swiper-pagination-bullet-active {
          background: #327bfa !important;
          width: 32px !important;
        }

        &:hover {
          background: #327bfa !important;
        }
      }
    }
  }
}
.news-container {
  padding: 40px 0;
  background: #fff;

  .news-category {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;

    .category-list {
      display: flex;
      gap: 20px;
      .category-divider {
        color: #b3b3b3;
      }
      .category-item {
        font-size: 16px;
        color: #1a1a1a;
        cursor: pointer;
        padding-bottom: 10px;
        position: relative;
        transition: all 0.3s ease;

        &.active {
          font-size: 20px;
          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 25%;
            width: 50%;
            height: 2px;
            background: #327bfa;
          }
        }

        &:hover {
          color: #327bfa;
        }
      }
    }

    .more-btn {
      padding: 8px 20px;
      border: 1px solid #327bfa;
      color: #327bfa;
      border-radius: 4px;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        background: #327bfa;
        color: #fff;
      }
    }
  }

  .news-content {
    display: flex;
    gap: 30px;

    .news-left {
      flex: 0 0 50%;
      height: 100%;

      .news-main {
        position: relative;
        overflow: hidden;
        border-radius: 8px;
        background: #fff;
        height: 100%;
        display: flex;
        flex-direction: column;

        img {
          width: 100%;
          height: 280px;
          object-fit: cover;
          transition: transform 0.3s ease;
        }

        .news-info {
          padding: 5px 10px;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          cursor: pointer;
          .news-main-title {
            font-size: 16px;
            margin: 8px 0px;
            color: #1a1a1a;
          }
          .desc {
            font-size: 14px;
            color: #666;
            line-height: 1.5;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            margin: 0;
          }

          p {
            font-size: 16px;
            line-height: 1.5;
            color: #666;
          }
        }

        &:hover {
          img {
            transform: scale(1.05);
          }
        }
      }
    }

    .news-right {
      flex: 0 0 50%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      height: 100%;

      .news-item {
        flex: 1;
        display: flex;
        gap: 20px;
        border-radius: 8px;
        transition: all 0.3s ease;
        align-items: stretch;
        cursor: pointer;

        img {
          width: 110px;
          height: 70px;
          object-fit: cover;
          border-radius: 4px;
        }

        .news-text {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .new-title {
            font-size: 16px;
            color: #1a1a1a;
            margin-bottom: 8px;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }

          p {
            font-size: 14px;
            color: #666;
            line-height: 1.5;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            margin: 0;
          }
        }

        &:hover {
          background: #f5f8fc;
          transform: translateY(-2px);
        }
      }
    }
  }
}
.product-container {
  background: url("@/assets/images/core_product_bg.png") no-repeat center;
  background-size: cover;
  padding: 20px 0 40px 0;

  .product-title {
    text-align: left;
    margin-bottom: 40px;

    h2 {
      font-size: 32px;
      color: #1a1a1a;
      margin-bottom: 10px;
      margin-top: 12px;
    }

    p {
      font-size: 16px;
      color: #808080;
    }
  }

  .product-list {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 22px;

    .product-box {
      background: url("@/assets/images/product_box.png") no-repeat center;
      background-size: cover;
      padding: 0px 8px;
      border-radius: 8px;
      height: 100%;
      min-height: 360px;

      .product-content {
        height: 100%;

        .title {
          font-size: 16px;
          color: #19191a;
          text-align: center;
          display: flex;
          justify-content: center;
          padding: 10px 0;
        }

        .product-items {
          .item {
            font-size: 12px;
            color: #19191a;
            margin-bottom: 12px;
            line-height: 1.4;
            cursor: pointer;
            transition: all 0.3s ease;
            padding-left: 20px;
            background: url("@/assets/images/product_icon.png") no-repeat left
              center;
            background-size: 14px;

            &:hover {
              color: #327bfa;
              transform: translateX(5px);
            }
          }
        }
      }
    }
  }
}
.solution-container {
  background-color: #ffffff;
  padding: 60px 0;

  .solution-content {
    display: flex;
    height: 480px;

    .solution-left {
      width: 270px;
      padding: 40px 20px;
      background: url("@/assets/images/solution_left_bg.png") no-repeat
        center/cover;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      color: #1a1a1a;
      h2 {
        font-size: 32px;
        margin-bottom: 0px;
        color: #1a1a1a;
      }

      p {
        font-size: 14px;
        margin-bottom: 40px;
        line-height: 1.5;
        color: #808080;
      }

      .more-btn {
        width: 120px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border: 1px solid #3172e0;
        border-radius: 20px;
        cursor: pointer;
        transition: all 0.3s;
        background-color: #3172e0;
        color: #fefeff;

        &:hover {
          background: #fff;
          color: #333;
        }
      }
    }

    .solution-right {
      flex: 1;
      margin-left: 20px;

      .solution-grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 20px;
        height: 100%;

        .solution-item {
          background: #f1f5f8;
          border-radius: 4px;
          overflow: hidden;
          transition: all 0.3s;
          cursor: pointer;

          &:hover {
            transform: translateY(-5px);
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
          }

          img {
            width: 100%;
            height: 80%;
            object-fit: cover;
          }

          span {
            display: block;
            text-align: center;
            padding: 5px 0;
            font-size: 16px;
            color: #18191a;
          }
        }
      }
    }
  }
}
</style>