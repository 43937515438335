import request from '@/utils/request'

//获取轮播图
export function getBanner(){
  return request({
    url:'front/bannerList',
    method:'get'
  })
}
//getMenus
export function getMenus(){
  return request({
    url:'front/getMenus',
    method:'get'
  })
}
//获取新闻栏目
export function getContentCategory(){
  return request({
    url:'front/getContentCategory',
    method:'get'
  })
}
//getContentListByColumnId/{columnId}
 export function getContentListByColumnId(query){
  return request({
    url:`front/getContentListByColumnId`,
    method:'get',
    params: query
  })
}
//获取新闻列表
export function getHomeContentList(columnId){
  return request({
    url:`front/getHomeContentList/${columnId}`,
    method:'get'
  })
}
//solutionList
export function getSolutionList(){
  return request({
    url:'front/solutionList',
    method:'get'
  })
}
//获取产品详情
export function getProductDetail(columnId){
  return request({
    url:`front/getProductDetail/${columnId}`,
    method:'get'
  })
}
//getCustomerCaseToFront
export function getCustomerCaseToFront(){
  return request({
    url:'front/getCustomerCaseToFront',
    method:'get'
  })
}
//aboutUseInfo
export function aboutUseInfo(){
  return request({
    url:'front/aboutUseInfo',
    method:'get'
  })
}
//selectContentById
export function selectContentById(columnId){
  return request({
    url:`front/selectContentById/${columnId}`,
    method:'get'
  })
}

export function submittCustomerConsult(data){
  return request({
    url:'front/submittCustomerConsult',
    method:'post',
    data
  })
}
//getBannerImage/{columnId}
 export function getBannerImage(columnId){
  return request({
    url:`front/getBannerImage/${columnId}`,
    method:'get'
  })
}