<template>
  <div id="app">
    <div class="nav-menu">
      <el-row>
        <el-col :span="18" :offset="3">
          <div class="navigation">
            <div class="logo" @click="$router.push('/')">
              <img src="@/assets/images/LWlogo.png" alt="公司logo">
            </div>
            <div class="menu">
              <div v-for="item in menuData" :key="item.columnName" class="menu-item"
                @mouseenter="handleMouseEnter(item)" @mouseleave="handleMouseLeave(item)">
                <template v-if="!item.children || !item.children.length">
                  <router-link v-if="item.url != '/'"  :to="item.url+'/'+item.id" exact-active-class="active">
                    {{ item.columnName }}
                  </router-link>
                  <router-link v-else  :to="item.url" exact-active-class="active">
                    {{ item.columnName }}
                  </router-link>
                </template>

                <div v-else class="menu-link" :class="{'active': $route.path.startsWith(item.url)}">
                  {{ item.columnName }}
                </div>
              </div>
            </div>
            <div class="telephone">
              <img src="@/assets/images/phone.png" alt="电话图标" class="phone-icon">
              <span class="phone-number">025-52850161</span>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="sub-nav-menu"
      v-if="currentHoverItem && currentHoverItem.children &&currentHoverItem.children.length"
      @mouseenter="handleSubNavMouseEnter" @mouseleave="handleSubNavMouseLeave">
      <el-row>
        <el-col :span="18" :offset="3">
          <div class="submenu-content">
            <div class="submenu-left">
              <div v-for="(subItem, index) in currentHoverItem.children" :key="subItem.columnName"
                class="submenu-section" :class="{'active': activeSubMenu === index}"
                @click="handleSubMenuClick(index)">
                <h3>
                  <img src="@/assets/images/menu_icon1.png" alt="menu icon">
                  {{ subItem.columnName }}
                </h3>
              </div>
            </div>
            <div class="submenu-right" v-if="currentHoverItem.children[activeSubMenu]?.children">
              <div class="submenu-items">
                <router-link v-for="thirdItem in currentHoverItem.children[activeSubMenu].children"
                  :key="thirdItem.url+'/'+thirdItem.id" :to="thirdItem.url+'/'+thirdItem.id"
                  class="submenu-item" @click.stop="handleSubNavMouseLeave">
                  <img src="@/assets/images/menu_icon2.png" alt="submenu icon">
                  {{ thirdItem.columnName }}
                </router-link>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <router-view :key="$route.fullPath" />
    <footer class="footer">
      <el-row>
        <el-col :span="18" :offset="3">
          <div class="footer-content">
            <div class="footer-section">
              <h3>公司本部</h3>
              <p>南京经济技术开发区智路6号</p>
              <p>兴智科技园B栋8楼</p>
              <p>公司本部电话：025-52850161</p>
            </div>
            <div class="footer-section">
              <h3>武汉办事处</h3>
              <p>湖北省武汉市洪山区关山街道光谷时代广场B座2604</p>
              <p>武汉办事处电话：18252086692</p>
              <p>武汉办事处邮箱：quanxl9518@163.com</p>
            </div>
            <div class="footer-section">
              <h3>邮箱/网址</h3>
              <p>公司邮箱：longwin@longwi.com</p>
              <p>公司邮箱：zhaopin@longwi.com</p>
              <p>公司网址：www.longwi.cn</p>
            </div>
          </div>
          <div class="footer-copyright">
            <p>© 2025南京朗赢信息技术有限公司 <span class="divider">|</span> <a
                href="https://beian.miit.gov.cn/" target="_blank"
                class="icp-link">苏ICP备17041563号</a></p>
          </div>
        </el-col>
      </el-row>
    </footer>
    <div class="floating-box">
      <div class="floating-item" @click="openOnlineChat">
        <img src="@/assets/images/zixun.png" alt="在线咨询">
        <span>在线咨询</span>
      </div>
      <div class="floating-item" @click="showConsultDialog">
        <img src="@/assets/images/right_icon1.png" alt="在线咨询">
        <span>业务咨询</span>
      </div>
      <el-popover placement="left" trigger="hover" popper-class="contact-popover" width="190">
        <div class="contact-info">
          <p>联系电话：025-52850161</p>
        </div>
        <div class="floating-item" slot="reference">
          <img src="@/assets/images/right_icon2.png" alt="联系电话">
          <span>联系电话</span>
        </div>
      </el-popover>
      <el-popover placement="left" trigger="hover" popper-class="contact-popover" width="190">
        <div class="qrcode-info">
          <img src="@/assets/images/qrcode.jpg" alt="企业微信二维码" class="qrcode-image">
        </div>
        <div class="floating-item" slot="reference">
          <img src="@/assets/images/right_icon3.png" alt="企业微信">
          <span>企业微信</span>
        </div>
      </el-popover>
    </div>
  </div>
</template>

<script>
import { getMenus } from "@/api/frontApi";
import { handleTree } from "@/utils/common";
export default {
  data() {
    return {
      currentHoverItem: null,
      isHoveringSubNav: false,
      activeSubMenu: 0,
      menuData: [],
      dialogVisible: false,
      consultForm: {
        name: "",
        phone: "",
        email: "",
        company: "",
        intention: "",
      },
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入电话号码", trigger: "blur" },
          {
            pattern: /^1[3-9]\d{9}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        email: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          { type: "email", message: "请输入正确的邮箱地址", trigger: "blur" },
        ],
        company: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
        intention: [
          { required: true, message: "请输入咨询意向", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.loadMenus();
  },
  created() {},
  methods: {
    //加载菜单
    loadMenus() {
      getMenus().then((res) => {
        let menus = handleTree(res.data, "id");
        this.menuData = menus;
        console.log(this.menuData);
      });
    },
    handleMouseEnter(item) {
      this.currentHoverItem = item;
      if (item.children && item.children.length) {
        this.activeSubMenu = 0;
      }
    },
    handleMouseLeave(item) {
      // 使用setTimeout来延迟菜单的隐藏，给用户足够时间移动到子菜单
      setTimeout(() => {
        if (!this.isHoveringSubNav) {
          this.currentHoverItem = null;
        }
      }, 5000);
    },
    handleSubNavMouseEnter() {
      this.isHoveringSubNav = true;
    },
    handleSubNavMouseLeave() {
      this.isHoveringSubNav = false;
      this.currentHoverItem = null;
    },
    handleSubMenuClick(index) {
      this.activeSubMenu = index;
    },
    showConsultDialog() {
      if (this.$route.path !== '/consult/5') {
        this.$router.push("/consult/5").catch(err => {
          // 忽略重复导航的错误
          if (err.name !== 'NavigationDuplicated') {
            throw err;
          }
        });
      }
    },
    openOnlineChat() {
      window.open("http://119.3.39.244:8081/chatIndex?ent_id=3");
    },
    submitForm() {
      this.$refs.consultForm.validate((valid) => {
        if (valid) {
          // TODO: 在这里添加提交表单的逻辑
          console.log("表单提交成功", this.consultForm);
          this.dialogVisible = false;
          this.$message.success("提交成功");
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: "AlibabaPuHuiTi-Medium", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
}

.nav-menu {
  width: 100%;
  height: 70px;
  background-color: #ffffff;
  position: relative;
  z-index: 100;
  padding: 0 5px;
  .navigation {
    margin: 0 auto;
    padding: 0 15px;
    height: 68px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 800px;
  }

  .logo {
    cursor: pointer;
    img {
      height: 40px;
      width: 246px;
    }
  }

  .menu {
    display: flex;
    flex-shrink: 0;

    .menu-item {
      margin-right: 50px;
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;

      &:last-child {
        margin-right: 0;
      }

      a,
      .menu-link {
        font-size: 20px;
        color: #333333;
        text-decoration: none;
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        transition: color 0.3s ease;
        white-space: nowrap;
        cursor: pointer;

        &:after {
          content: "";
          position: absolute;
          bottom: -19px;
          left: 50%;
          width: 0;
          height: 2px;
          background: linear-gradient(to right, #3172e0, #4a8bff);
          transform: translateX(-50%);
          transition: all 0.3s ease;
        }

        &:hover,
        &.active {
          color: #3172e0;
          background: linear-gradient(to right, #3172e0, #4a8bff);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;

          &:after {
            width: 100%;
          }
        }
      }
    }
  }
}

.sub-nav-menu {
  width: 100%;
  background-color: #f5f8fc;
  position: absolute;
  z-index: 9999;
  margin-top: 1px;

  .submenu-content {
    padding: 30px 10px;
    display: flex;
    color: #19191a;
  }

  .submenu-left {
    width: auto;
    display: flex;
    flex-direction: column;
    padding-right: 40px;
    border-right: 1px solid #e8ecf3;
  }

  .submenu-right {
    flex: 1;
    padding-left: 40px;
  }

  .submenu-section {
    padding: 15px 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    background-color: #fff;
    margin-bottom: 25px;
    border-radius: 8px;

    h3 {
      font-size: 18px;
      font-weight: normal;
      margin: 0;
      color: #19191a;
      display: flex;
      align-items: center;

      img {
        width: 15px;
        height: 15px;
        margin-right: 10px;
      }
    }

    &.active {
      background-color: #3172e0;
      h3 {
        color: #fff;
      }
    }

    &:hover:not(.active) {
      background-color: #fff;
    }
  }

  .submenu-items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 25px;
  }

  .submenu-item {
    color: #19191a;
    text-decoration: none;
    font-size: 14px;
    transition: color 0.3s ease;
    padding: 6px 12px;
    border-radius: 4px;
    width: 220px;
    display: flex;
    align-items: center;

    img {
      width: 35px;
      height: 35px;
      margin-right: 10px;
    }

    &:hover {
      background-color: #ffffff;
      color: #3172e0;
    }
  }
}
.floating-box {
  position: fixed;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .contact-info {
    p {
      margin: 8px 0;
      font-size: 14px;
      color: #333;
    }
  }
  .floating-item {
    width: 55px;
    height: 60px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
    img {
      width: 20px;
      height: 20px;
      margin-bottom: 8px;
    }
    span {
      font-size: 12px;
      color: #333;
      text-align: center;
    }
    &:hover {
      transform: translateX(-5px);
      background: #3172e0;
      span {
        color: #fff;
      }
    }
  }
}
.telephone {
  display: flex;
  align-items: center;
  gap: 10px;

  .phone-icon {
    width: 24px;
    height: 24px;
  }

  .phone-number {
    font-size: 20px;
    color: #333333;
  }
}
.footer {
  background-color: #2e3440;
  padding: 40px 0;
  color: #ffffff;

  .footer-content {
    display: flex;
    justify-content: space-between;
    min-width: 800px;
    padding: 0 15px;
    margin-bottom: 30px;
  }

  .footer-section {
    h3 {
      font-size: 20px;
      margin-bottom: 20px;
      font-weight: normal;
    }

    p {
      font-size: 16px;
      margin: 10px 0;
      color: rgba(255, 255, 255, 0.8);
    }
  }

  .footer-copyright {
    text-align: center;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 20px;

    p {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.6);
      margin: 0;
    }

    .divider {
      margin: 0 10px;
      color: rgba(255, 255, 255, 0.3);
    }

    .icp-link {
      color: rgba(255, 255, 255, 0.6);
      text-decoration: none;

      &:hover {
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }
}
.qrcode-info {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;

  .qrcode-image {
    width: 150px;
    height: 150px;
    object-fit: contain;
  }
}
</style>
