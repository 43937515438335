import axios from "axios";
import { Message } from "element-ui";

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // 从环境变量中获取API基础地址
  timeout: 50000, // 请求超时时间
  withCredentials: true, // 跨域请求时发送cookies
});

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // 在发送请求之前做些什么
    // 例如：可以在这里统一添加token
    // if (store.getters.token) {
    //   config.headers['Authorization'] = 'Bearer ' + store.getters.token;
    // }
    return config;
  },
  (error) => {
    // 处理请求错误
    console.log(error);
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    // 根据后端约定的响应格式判断请求是否成功
    if (res.code !== 200) {
      Message({
        message: res.msg || "错误",
        type: "error",
        duration: 5 * 1000,
      });
      return Promise.reject(new Error(res.msg || "错误"));
    } else {
      return res;
    }
  },
  (error) => {
    console.log("err" + error);
    Message({
      message: error.message,
      type: "error",
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);
export default service;